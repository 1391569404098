import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71')
];

export const server_loads = [2,3,7,5,6];

export const dictionary = {
		"/(frontend)": [~9,[2]],
		"/(frontend)/archiv": [~32,[2,3]],
		"/(frontend)/archiv/[id]": [~33,[2,3]],
		"/(frontend)/(content-routes)/chat": [10,[2]],
		"/(frontend)/(content-routes)/detail/announcement/[id]": [~11,[2]],
		"/(frontend)/(content-routes)/detail/notification": [~12,[2]],
		"/einrichtungsassistent": [~71,[8]],
		"/(frontend)/(content-routes)/forms/announcement/[[id]]": [13,[2]],
		"/(frontend)/(content-routes)/forms/documentFile/[[id]]": [21,[2]],
		"/(frontend)/(content-routes)/forms/document/companyAgreement/[[id]]": [14,[2]],
		"/(frontend)/(content-routes)/forms/document/correspondence/[[id]]": [15,[2]],
		"/(frontend)/(content-routes)/forms/document/general/[[id]]": [16,[2]],
		"/(frontend)/(content-routes)/forms/document/invitation/[[id]]": [17,[2]],
		"/(frontend)/(content-routes)/forms/document/protocol/[[id]]": [18,[2]],
		"/(frontend)/(content-routes)/forms/document/resolution/[[id]]": [19,[2]],
		"/(frontend)/(content-routes)/forms/document/wageAgreement/[[id]]": [20,[2]],
		"/(frontend)/(content-routes)/forms/event/[[id]]": [22,[2]],
		"/(frontend)/(content-routes)/forms/mandator": [23,[2]],
		"/(frontend)/(content-routes)/forms/phoneCall/[[id]]": [24,[2]],
		"/(frontend)/(content-routes)/forms/photoCollection/[[id]]": [26,[2]],
		"/(frontend)/(content-routes)/forms/photo/[[id]]": [25,[2]],
		"/(frontend)/(content-routes)/forms/role/[[id]]": [27,[2]],
		"/(frontend)/(content-routes)/forms/task/[[id]]": [28,[2]],
		"/(frontend)/(content-routes)/forms/team/[[id]]": [29,[2]],
		"/(frontend)/(content-routes)/forms/user/settings": [31,[2]],
		"/(frontend)/(content-routes)/forms/user/[[id]]": [30,[2]],
		"/(login)/login": [~65,[7]],
		"/(login)/login/2fa": [~66,[7]],
		"/(login)/logout": [~67,[7]],
		"/(frontend)/meine-arbeit": [~34,[2,4]],
		"/(frontend)/meine-arbeit/aufgaben": [~35,[2,4]],
		"/(frontend)/meine-arbeit/dokumente": [~36,[2,4]],
		"/(frontend)/meine-arbeit/dokumente/allgemein": [~37,[2,4]],
		"/(frontend)/meine-arbeit/dokumente/einladungen": [~38,[2,4]],
		"/(frontend)/meine-arbeit/dokumente/protokolle": [~39,[2,4]],
		"/(frontend)/meine-arbeit/dokumente/schriftwechsel": [~40,[2,4]],
		"/(frontend)/meine-arbeit/telefonliste": [~41,[2,4]],
		"/(frontend)/meine-arbeit/termine": [~42,[2,4]],
		"/(login)/password-forgot": [~68,[7]],
		"/(login)/password-reset/[token]": [~69,[7]],
		"/(login)/refresh": [~70,[7]],
		"/(frontend)/sekretariat": [~43,[2,5]],
		"/(frontend)/sekretariat/ankuendigungen": [~44,[2,5]],
		"/(frontend)/sekretariat/berichte": [45,[2,5]],
		"/(frontend)/sekretariat/einstellungen": [~46,[2,5]],
		"/(frontend)/sekretariat/funktionen": [~47,[2,5]],
		"/(frontend)/sekretariat/personen": [~48,[2,5]],
		"/(frontend)/sekretariat/teams": [~49,[2,5]],
		"/(frontend)/team/[id]": [~50,[2,6]],
		"/(frontend)/team/[id]/aufgaben": [~51,[2,6]],
		"/(frontend)/team/[id]/dokumente": [~52,[2,6]],
		"/(frontend)/team/[id]/dokumente/allgemein": [~53,[2,6]],
		"/(frontend)/team/[id]/dokumente/beschluesse": [~54,[2,6]],
		"/(frontend)/team/[id]/dokumente/betriebsvereinbarungen": [~55,[2,6]],
		"/(frontend)/team/[id]/dokumente/einladungen": [~56,[2,6]],
		"/(frontend)/team/[id]/dokumente/protokolle": [~57,[2,6]],
		"/(frontend)/team/[id]/dokumente/schriftwechsel": [~58,[2,6]],
		"/(frontend)/team/[id]/dokumente/tarifvertraege": [~59,[2,6]],
		"/(frontend)/team/[id]/fotos": [~60,[2,6]],
		"/(frontend)/team/[id]/fotos/[photoCollectionId]": [~61,[2,6]],
		"/(frontend)/team/[id]/mitglieder": [~62,[2,6]],
		"/(frontend)/team/[id]/telefonliste": [~63,[2,6]],
		"/(frontend)/team/[id]/termine": [~64,[2,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';