import * as Sentry from '@sentry/sveltekit';
import '$lib/util/zodHelper';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://a00ff8cf4e1b715f17fd10c2b6b52b5e@sentry.concedra.de/2",
		environment: import.meta.env.MODE,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.replayIntegration()]
})

// export const handleError = Sentry.handleErrorWithSentry();
